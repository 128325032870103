import React, {useState, useEffect} from 'react'
import {Button} from "react-bootstrap"

import {useHistory} from "react-router-dom";
import Database from '../../components/database';
import Logged from '../../components/logged';
import Message from '../../components/message';

import "./style.css"

const defaultData = {
    "password": ""
}

function Login() {
// -------- STATES ---------------------

    const [data, setData] = useState(defaultData)
    const [loginButtonClicked, setLoginButtonClicked] = useState(false)
    const [closeMessage, setCloseMessage] = useState(true)
    const history = useHistory()

// -------- HANDLER FUNCTION -----------

    function changeHandler(event) {
        let name = event.target.name
        let value = event.target.value
        
        setData({
            ...data,
            [name]: value
        })
    }

    function submitHandler() {
        let DB = new Database()
        DB.login(data.password, (r) => {
            if(!r.errors) history.push('/')
            else setCloseMessage(false)
            setLoginButtonClicked(false)
        })
        setLoginButtonClicked(true)
    }

// --------- COMPONENT STRUCTURE -------

    return (
        <Logged>
            <>
                <div className="middle-screen full-screen">
                    <div className="my-container gray-bkg">
                        <h1 className="text-center mb-0">Accedi</h1>
                        <br />
                        <input className="text-center"
                        type="text"
                        name="password"
                        value={data.password}
                        onChange={(event) => changeHandler(event)} placeholder="Codice..."
                        onKeyPress={(e) => e.key === "Enter" ? submitHandler() : !closeMessage ? setCloseMessage(true) : null}/>
                        <br />
                        <br />
                        <div className="text-center">
                            <Button onClick={() => submitHandler()}>{loginButtonClicked ? "Accesso..." : "Accedi"}</Button>
                        </div>
                    </div>
                </div>
                {
                    !closeMessage ?
                    <Message error={true} message="Errore, password errata." function={() => setCloseMessage(!closeMessage)}/>
                    : ""
                }
            </>
        </Logged>
    )
}

export default Login
