import React from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import "./style.css"

function Popup(props) {
  let body = props.children
  let onClose = props.onClose
  let open = props.open

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box className='popup'>
        {body}
      </Box>
    </Modal>
  )
}

export default Popup