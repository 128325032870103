import React, {useEffect, useState} from 'react'
import Database from "../database"
import { useHistory } from 'react-router'

import preloader from "../../img/icons/preloader.gif"

function Logged(props) {
    const [isLogged, setIsLogged] = useState("")
    const history = useHistory()

    useEffect(() => {
        let DB = new Database()
        DB.isLogged((r) => setIsLogged(r.isLogged))
        // setIsLogged(true)
    }, [])

    useEffect(() => {
        if(isLogged == true) history.push('/')
        else if (isLogged == null) history.push('/login')
    }, [isLogged])

    return (
        <>
            {isLogged == "" ? 
            <div className="full-screen middle-screen">
                <img className="img-fluid" src={preloader}/>
            </div>
            : props.children}
        </>
    )
}

export default Logged
