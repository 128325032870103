
class Driver {
    constructor(info = null, isPointRace, trialsList) {
        if(info == null) {
            this.id = ""
            this.driver_code = ""
            this.race_number = ""
            this.name = ""
            this.surname = ""
            trialsList = trialsList == undefined ? [] : trialsList
            for(let element of Object.keys(trialsList)) {
                this[element] = isPointRace ? "--" : "00:00:000"
            }
        } else {
            this.id = info.id_mask != undefined ? info.id_mask : ""
            this.driver_code = info.driver_code != undefined ? info.driver_code : ""
            this.race_number = info.race_number != undefined ? info.race_number : ""
            this.name = info.name != undefined ? info.name : ""
            this.surname = info.surname != undefined ? info.surname : ""
            trialsList = trialsList == undefined ? [] : trialsList
            for(let element of Object.keys(trialsList)) {
                if(isPointRace)
                    this[element] = info[element] != undefined ? info[element] : "--"
                else {
                    this[element] = info[element] != undefined ? info[element] : "00:00:000"
                }
            }
        }
    }
}

export default Driver