import React, {useState, useEffect} from 'react'
import { Row, Col } from 'react-bootstrap'

import {ReactComponent as ExitIcon} from "../../img/icons/exit.svg"

import "./style.css"
/**
 * props:
 *      _defaultData_
 *      funtion that removes the component from the page
 */

let defaultData = {
    "error" : false,
    "message" : "",
    "function" : null
}

function Message(props) {

// ----------- STATES --------------

    const [data, setData] = useState(defaultData)

// ----------- USESTATE ------------

    useEffect(() => {
        setData({
            "error" : props.error,
            "message" : props.message,
            "function" : props.function
        })
    }, [])

// ----------- USEEFFECT -----------

    return (
        <div id="message-container">
            <div className="middle-screen">
                <div id="message" className="middle-screen">
                    <Row>
                        <Col sm="1" className="circle-container">
                            <div className={"circle" + (props.error ? " red" : " green")}></div>
                        </Col>
                        <Col sm="9">
                            <p className="mb-0 text-center font-weight-light" id="circle-container">{data.message}</p>
                        </Col>
                        <Col sm="1" className="circle-container">
                            <ExitIcon className="exit-icon" onClick={() => props.function()}/>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Message
