import $ from "jquery";

class Database {
    constructor(){
        // this._rootpath = "http://livetiming.local"
        this._rootpath = "https://livetappe.pro022.com"
        // this._rootpath = ""
    }

    isLogged(callback) {
        $.ajax({
            method: 'post',
            url: this._rootpath + '/is_logged',
            cache: false,
            success: callback
        })
    }

    login(psw, callback) {
        $.ajax({
            method: 'post',
            url: this._rootpath + '/login',
            data: {
                password: psw
            },
            success: (result) => callback(result)
        })
    }

    getTrials(callback) {
        $.ajax({
            method: 'get',
            url: this._rootpath + '/get_trials',
            success: (result) => callback(result)
        })
    }

    getDrivers(callback) {
        $.ajax({
            method: 'post',
            url: this._rootpath + '/get_drivers',
            success: (result) => callback(result)
        })
    }

    async setDriver(driver, callback) {
        return $.ajax({
            method: 'post',
            url: this._rootpath + '/set_driver',
            data: {
                ...driver
            },
            success: (result) => callback(result)
        })
    }

    async setDrivers(drivers, callback) {
        return $.ajax({
            contentType: "application/json",
            method: 'post',
            url: this._rootpath + '/set_drivers',
            data: JSON.stringify({
                'drivers' : drivers
            }),
            success: (result) => callback(result)
        })
    }

    deleteDriver(driver, callback) {
        $.ajax({
            method: 'post',
            url: this._rootpath + '/delete_driver',
            data: {
                "id" : driver.id
            },
            success: (result) => callback(result)
        })
    }

    getRaceType(callback) {
        $.ajax({
            method: 'post',
            url: this._rootpath + '/get_race_type',
            success: (result) => callback(result)
        })
    }

    setRaceType(value, callback) {
        $.ajax({
            method: 'post',
            url: this._rootpath + '/set_race_type',
            data: {
                "value" : value
            },
            success: (result) => callback(result)
        })
    }

    deleteAll(callback = () => {}) {
        $.ajax({
            method: 'post',
            url: this._rootpath + '/delete_all',
            success: (result) => callback(result)
        })
    }

    addTrial(name, isPointRace, callback) {
        $.ajax({
            method: 'post',
            url: this._rootpath + '/create_trial',
            data: {
                "name": name,
                "race_type": isPointRace ? "point" : "time"
            },
            success: (result) => callback(result)
        })
    }

    deleteTrial(ID, callback) {
        $.ajax({
            method: 'post',
            url: this._rootpath + '/delete_trial',
            data: {
                "ID": ID
            },
            success: (result) => callback(result)
        })
    }

    editTrial(ID, name, callback) {
        $.ajax({
            method: 'post',
            url: this._rootpath + '/update_trial',
            data: {
                "ID": ID,
                "name": name
            },
            success: (result) => callback(result)
        })
    }
}

export default Database
