import React, {useState, useEffect} from 'react'
import Switch from "react-switch";
import Message from "../../components/message"
import Logged from "../../components/logged"

import {ReactComponent as ExitIcon} from "../../img/icons/exit.svg"
import {ReactComponent as AddIcon} from "../../img/icons/add.svg"
import {ReactComponent as TrashIcon} from "../../img/icons/trash.svg"
import {ReactComponent as EditIcon} from "../../img/icons/edit.svg"

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import "./style.css"
import {Row, Col, Container, Button} from "react-bootstrap"
import Driver from '../../models/Driver';

import Database from "../../components/database"
import Drawer from '@mui/material/Drawer';
import Popup from '../../components/popup';

const DB = new Database()

function Dashboard() {
    const ADD_TRIAL_MODE = "add"
    const EDIT_TRIAL_MODE = "edit"
    const DELETE_TRIAL_MODE = "delete"

// ------------ STATES -------------

    const [driverList, setDriverList] = useState({})
    const [switcherChecked, setSwitcherChecked] = useState(false) // CHECKED: time race | NOT CHECKED: points race
    const [isPointRace, setIsPointRace] = useState(false)
    const [opacity, setOpacity] = useState(1)
    const [trialsList, setTrialsList] = useState({})        // { ID : name }
    const [selectedDriver, setSelectedDriver] = useState(new Driver({name: "undefined"}, isPointRace, trialsList))
    const [messageInfo, setMessageInfo] = useState("")
    const [saving, setSaving] = useState(false)
    const [popupMode, setPopupMode] = useState(ADD_TRIAL_MODE)
    const [openTrialPopup, setOpenTrialPopup] = useState(false)
    const [newTrialName, setNewTrialName] = useState("")
    const [trialMessage, setTrialMessage] = useState("")
    const [selectedTrialID, setSelectedTrialID] = useState(undefined)

    function formatTrials(trialList) {
        let trialObject = {}
        for(let element of trialList) {
            trialObject[element['ID']] = element['name']
        }
        return trialObject
    }

// ------------ HANDLER FUNCTION ------

    function handleSwitch() {
        setSaving(true)
        setSwitcherChecked(!switcherChecked)
        if(switcherChecked) {
            DB.setRaceType('mixed', () => setSaving(false))
        } else {
            DB.setRaceType('crono', (r) => {
                setSaving(false)
            })
        }
    }

    function handleIsPointRaceChange() {
        if(Object.values(driverList).length != 0) setMessageInfo("Eliminare tutti i piloti")
        else setIsPointRace(!isPointRace)
    }

    function makeSum(item) {
        if(isPointRace) {
            let sum = 0
            for(let element of Object.keys(trialsList)) {
                sum += isNaN(parseInt(item[element])) ? 0 : parseInt(item[element])
            }
            return sum
        }
        else {
            let timeSum = new Date("0 00:00:00:000").getTime()
            for(let element of Object.keys(trialsList)) {
                let element_time = new Date('0 00:' + item[element]).getTime()
                timeSum = new Date(timeSum + element_time).getTime()
            }
            
            timeSum = new Date(timeSum)
            let minutes = ('0' + timeSum.getMinutes()).slice(-2)
            let seconds = ('0' + timeSum.getSeconds()).slice(-2)
            let milliseconds = ('00' + timeSum.getMilliseconds()).slice(-3)

            return minutes + ':' + seconds + ':' + milliseconds
        }
    }

    function downloadCSV() {
        let trials_header = [Object.values(trialsList), "Somma\n"].join(",")
        let CSVcontent = "data:text/csv;charset=utf-8,Codice,Numero Gara,Cognome,Nome" + (trials_header != "" ? ("," + trials_header) : "\n")
        Object.values(driverList).map(item => {
            item = {...item, sum: makeSum(item)}
            CSVcontent += item.driver_code + ', ' + item.race_number + "," + item.surname + ', ' + item.name
            for(let element of Object.keys(trialsList)) CSVcontent += ', ' + item[element]
            if(Object.keys(trialsList) != 0) CSVcontent += ', ' + item.sum
            CSVcontent += "\n"
        })

        let encodedUri = encodeURI(CSVcontent);
        window.open(encodedUri)
    }

    function openAddTrial() {
        setNewTrialName("")
        setPopupMode(ADD_TRIAL_MODE)
        setOpenTrialPopup(true)
    }
    
    function openDeleteTrial(ID) {
        setSelectedTrialID(ID)
        setPopupMode(DELETE_TRIAL_MODE)
        setOpenTrialPopup(true)
    }

    function openEditTrial(ID) {
        setSelectedTrialID(ID)
        setPopupMode(EDIT_TRIAL_MODE)
        setNewTrialName(trialsList[ID])
        setOpenTrialPopup(true)
    }

    function addTrial(name) {
        if(name != "") {
            setTrialMessage("Invio...")
            DB.addTrial(name, isPointRace, (r) => {
                if(r['error']) {
                    setTrialMessage(r['description'])
                } else {
                    let ID = r['ID']
                    trialsList[ID] = name
                    setNewTrialName("")
                    setTrialMessage("")

                    for(let driver of Object.values(driverList)) {
                        driver[ID] = isPointRace ? "--" : "00:00:000"
                    }

                    setOpenTrialPopup(false)
                }
            })
        }
    }

    function deleteTrial(ID) {
        setTrialMessage("Elimino...")
        DB.deleteTrial(ID, () => {
            for(let driver of Object.values(driverList)) delete driver[ID]
            delete trialsList[ID]
            setTrialMessage("")
            setSelectedTrialID(undefined)
            setOpenTrialPopup(false)
        })
    }

    function editTrial(ID, name) {
        setTrialMessage("Modifico...")
        if(name != "") {
            DB.editTrial(ID, name,  () => {
                trialsList[ID] = name
                setTrialMessage("")
                setSelectedTrialID(undefined)
                setOpenTrialPopup(false)
            })
        }
    }

// ------------ USEEFFECT -------------

    useEffect(() => {
        DB.getRaceType((r) => setSwitcherChecked(r.value == 'crono'))
        DB.getTrials((r) => setTrialsList(formatTrials(r)))
    }, [])

    useEffect(() => {
        if(switcherChecked) setOpacity(0.3)
        else setOpacity(1)
    }, [switcherChecked])

    useEffect(() => {
        if(Object.keys(driverList).length == 0) setSelectedDriver(new Driver({name: 'undefined'}, isPointRace, trialsList))
    }, [driverList])

    return (
        <Logged>
            <div id="dashboard">
                <div id="title">
                    <Container>
                        <h1>LiveTappe</h1>
                    </Container>
                </div>

                <br />

                <Container>
                    <div className="justify-content-between">
                        <div className="display-inline">
                            <h3 className="m-3">Gara cronometro</h3>
                            <Switch uncheckedIcon={false}
                            checkedIcon={false}
                            onChange={() => handleSwitch()}
                            checked={switcherChecked}
                            offColor="#333333"
                            onColor="#D4D4D4"
                            offHandleColor="#292929"
                            onHandleColor="#292929"
                            handleDiameter={20}/>
                        </div>
                        <div style={{display: "grid", placeItems: "center"}}>
                            <p className="m-0">{saving == true ? "Salvataggio..." : saving == null ? "Non salvato" : "Tutto salvato"}</p>
                        </div>
                    </div>

                    <br />
                    
                    <div style={{opacity:opacity, pointerEvents: opacity == 1 ? "unset" : "none"}}>
                        <div className="text-center">
                            <div className="display-inline text-center">
                                <h5 className="m-3">Gara a tempo</h5>
                                <Switch uncheckedIcon={false}
                                    checkedIcon={false}
                                    onChange={() => handleIsPointRaceChange()}
                                    checked={isPointRace}
                                    offColor="#333333"
                                    onColor="#D4D4D4"
                                    offHandleColor="#292929"
                                    onHandleColor="#292929"
                                    handleDiameter={20}/>
                                <h5 className="m-3">Gara a punti</h5>
                            </div>
                        </div>
                        <Accordion style={{color: "white"}}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon style={{fill: "white"}}/>}>
                                Gestione colonne
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    Object.values(trialsList).length == 0
                                    ? <p>Attualmente non ci sono prove...</p>
                                    : <div className='custom_trial_container'>
                                        {
                                            Object.keys(trialsList).map((ID) =>
                                                <div className='custom_trial'>
                                                    <p className=''>{trialsList[ID]}</p>
                                                    <EditIcon width="27px" height="17px" onClick={() => openEditTrial(ID)}/>
                                                    <TrashIcon width="27px" height="17px" onClick={() => openDeleteTrial(ID)}/>
                                                </div>
                                            )
                                        }
                                    </div>
                                }
                                <br/>
                                <div className='text-center'><Button onClick={openAddTrial}>Aggiungi</Button></div>
                            </AccordionDetails>
                        </Accordion>
                        <Popup
                            // title="Aggiungi prova"
                            open={openTrialPopup}
                            onClose={() => setOpenTrialPopup(false)}
                        >
                            <div>
                                <br/>
                                {
                                    popupMode == ADD_TRIAL_MODE &&
                                    <>
                                        <h4>Aggiungi prova</h4>
                                        <input
                                            type="text"
                                            className="max-width"
                                            placeholder='Nome...'
                                            value={newTrialName}
                                            onChange={(e) => setNewTrialName(e.target.value)}
                                            style={{border: "1px solid var(--second-color)"}}
                                        />
                                        <br/>
                                        <br/>
                                        <p>{trialMessage}</p>
                                        <div className='text-center'>
                                            <Button className="m-2" onClick={() => addTrial(newTrialName)}>Salva</Button>
                                            <Button className="m-2" onClick={() => setOpenTrialPopup(false)}>Chiudi</Button>
                                        </div>
                                    </>
                                }
                                {
                                    popupMode == DELETE_TRIAL_MODE &&
                                    <>  
                                        <h4>Elimina prova</h4>
                                        {trialMessage}
                                        <div className='text-center'>
                                            <Button className="m-2" onClick={() => deleteTrial(selectedTrialID)}>Elimina</Button>
                                            <Button className="m-2" onClick={() => setOpenTrialPopup(false)}>Chiudi</Button>
                                        </div>
                                    </>
                                }
                                {
                                    popupMode == EDIT_TRIAL_MODE &&
                                    <>
                                        <h4>Modifica prova</h4>
                                        <input
                                            type="text"
                                            className="max-width"
                                            placeholder='Nome...'
                                            value={newTrialName}
                                            onChange={(e) => setNewTrialName(e.target.value)}
                                            style={{border: "1px solid var(--second-color)"}}
                                        />
                                        <br/>
                                        <br/>
                                        <p>{trialMessage}</p>
                                        <div className='text-center'>
                                            <Button className="m-2" onClick={() => editTrial(selectedTrialID, newTrialName)}>Salva</Button>
                                            <Button className="m-2" onClick={() => setOpenTrialPopup(false)}>Chiudi</Button>
                                        </div>
                                    </>
                                }
                            </div>
                        </Popup>
                        <Row className="m-3 justify-space-around" >
                            <DriverList 
                                driverList={driverList}
                                setDriverList={setDriverList}
                                selectedDriver={selectedDriver}
                                setSelectedDriver={setSelectedDriver}
                                setSaving={setSaving}
                                isPointRace={isPointRace}
                                setIsPointRace={setIsPointRace}
                                trialsList={trialsList}
                            />
                            <DriverForm
                                selectedDriver={selectedDriver}
                                setSelectedDriver={setSelectedDriver}
                                driverList={driverList}
                                setDriverList={setDriverList}
                                setMessage={setMessageInfo}
                                setSaving={setSaving}
                                isPointRace={isPointRace}
                                setIsPointRace={setIsPointRace}
                                trialsList={trialsList}
                                setTrialsList={setTrialsList}
                            />
                        </Row>
                    </div>
                    
                </Container>
                <div className="text-center" style={{opacity:opacity, pointerEvents: opacity == 1 ? "unset" : "none"}}>
                    <Button onClick={downloadCSV}>Scarica CSV</Button>
                </div>
                {messageInfo == "" ? "" : <Message error={true} message={messageInfo} function={() => setMessageInfo("")}/>}
            </div>
        </Logged>
    )
}

function DriverList(props) {
// ------------ STATES -------------

    const driverList = props.driverList
    const setDriverList = props.setDriverList
    const trialsList = props.trialsList
    const [searchWord, setSearchWord] = useState("")
    const [lastID, setLastID] = useState(0)
    const setSaving = props.setSaving
    const [loading, setLoading] = useState(true)
    const isPointRace = props.isPointRace
    const setIsPointRace = props.setIsPointRace
    const [newDriverFromCSV, setNewDriverFromCSV] = useState(undefined)
    const [postingDriverLabel, setPostingDriverLabel] = useState("Carica")
    
    let selectedDriver = props.selectedDriver
    let setSelectedDriver = props.setSelectedDriver

    const driverListHandler = {
        deleteDriver : deleteDriver,
        setSelectedDriver : setSelectedDriver,
        selectedDriver : selectedDriver,
        driverList : driverList
    }

// ------------ USEFFECT -----------

    useEffect(() => {
        DB.getDrivers((result) => {
            let list = []
            result.forEach((item, i) => {
                let driver = new Driver(item, isPointRace, trialsList)
                if(Object.keys(trialsList).length != 0) {
                    let first_trial_ID = Object.keys(trialsList)[0]
                    if(driver[first_trial_ID].includes(':')) setIsPointRace(false)
                    else setIsPointRace(true)
                }
                
                list[driver.id] = driver
            })
            setDriverList({...driverList, ...list})
            setLoading(false)
        })
    }, [trialsList])

// ------------ HANDLER FUNCTION -------------

    function deleteDriver(driver) {
        setSaving(true)
        DB.deleteDriver(driver, () => setSaving(false))
        let newList = driverList
        delete newList[driver.id]
        setDriverList({...newList})
        setSelectedDriver(new Driver({name: "undefined"}, isPointRace, trialsList))
    }

    function addDriver() {
        let newID = lastID
        let newDriver = new Driver({
                id_mask: newID,
                name: "Nuovo Pilota",
                driver_code: "Codice mancante"
            },
            isPointRace,
            trialsList
        )
        setLastID(lastID + 1)
        setDriverList({[newID]: newDriver, ...driverList})
        setSelectedDriver(newDriver)
    }

    function deleteAll() {
        setSaving(true)
        DB.deleteAll(() => setSaving(false))
        setDriverList({})
        setSelectedDriver(new Driver({name: "undefined"}, isPointRace, trialsList))
    }

    function loadDriversFromCSV(event) {
        let input = event.target;

        let reader = new FileReader();
        reader.onload = function(){
            let text = reader.result;
            // list of lists containing new driver info
            let allData = []

            // reading line by line
            for(let line of text.split("\n")) {
                // getting data separated by coma(FORMAT -> numero_di_gara, nome, cognome, codice_univoco)
                let info = line.split(';')
                if(info.length != 4) console.warn("Riga strana:", line)
                if(info.length < 4) console.warn("Riga non valida", line)
                else allData.push(info.slice(0, 4))
            }
            setNewDriverFromCSV(allData)
            event.target.value = ""
        };
        reader.readAsText(input.files[0], 'UTF-8');
    }

    function changeCSVDriverInfo(driverIndex, field, value) {
        newDriverFromCSV[driverIndex][field] = value
        setNewDriverFromCSV([...newDriverFromCSV])
    }

    async function postDriverFromCsv() {
        let drivers = []

        for(let driver of newDriverFromCSV) {
            let driverInfo = {
                name: driver[1].replaceAll("\r", "").replaceAll("\n", ""),
                surname: driver[2].replaceAll("\r", "").replaceAll("\n", ""),
                race_number: driver[0].replaceAll("\r", "").replaceAll("\n", ""),
                driver_code: driver[3].replaceAll("\r", "").replaceAll("\n", ""),
            }
            for(let element of Object.keys(trialsList)) {
                driverInfo[element] = isPointRace ? "--" : "00:00:000"
            }
            drivers.push(driverInfo)
        }

        DB.setDrivers(drivers, () => window.location.reload())
        setPostingDriverLabel("Carico...")
    }

    return (
        <Col md="4" className="gray-bkg my-column" id="left">
            <div className="driver-header">
                <div>
                    <h5>Piloti</h5>
                </div>
                <div>
                    <p className="delete-all" onClick={() => deleteAll()}>Elimina tutti</p>
                </div>
            </div>
            <input type="text" className="max-width" placeholder="Cerca pilota" value={searchWord} onChange={(e) => setSearchWord(e.target.value.toUpperCase())}/>
            <br />
            <br />
            {loading ? 
            <p>Caricamento...</p> : 
            <div id="driver-list">
                {Object.values(driverList).map(item => {
                    let searchWordLength = searchWord.length
                    if(item.name.substring(0, searchWordLength).toUpperCase() == searchWord |
                        item.surname.substring(0, searchWordLength).toUpperCase() == searchWord |
                        item.driver_code.substring(0, searchWordLength).toUpperCase() == searchWord)
                    return <DriverItem key={item.id} driverInfo={item} handler={driverListHandler} />
                    else return ""
                })}
            </div>
            }
            <div id="add-driver-container">
                <Row>
                    <Col>
                        <p className="text-center m-0" onClick={() => addDriver()}>
                            <AddIcon width="27px" height="17px"/>Aggiungi pilota
                        </p>
                    </Col>
                    <Col>
                        <input style={{display: "none"}} id="load_csv" type="file" onChange={(e) => loadDriversFromCSV(e)}/>
                        <label for="load_csv">
                            <p className="text-center m-0">Carica da CSV</p>
                        </label>
                    </Col>
                </Row>
            </div>
            <Drawer
                anchor={"right"}
                open={newDriverFromCSV != undefined}
                onClose={() => setNewDriverFromCSV(undefined)}
            >
                <div id="right_panel">
                    <Row>
                        <Col md="2"><h6>Codice gara</h6></Col>
                        <Col md="3"><h6>Nome</h6></Col>
                        <Col md="3"><h6>Cognome</h6></Col>
                        <Col md="2"><h6>Codice pilota</h6></Col>
                        <Col md="2"><button className="btn btn-primary" onClick={postDriverFromCsv}>{postingDriverLabel}</button></Col>
                        <br/>
                        <br/>
                        <hr/>
                        <br/>
                    </Row>
                    {
                        newDriverFromCSV && newDriverFromCSV.map((driver, i) => 
                            <Row>
                                <Col md="2"><input onChange={(e) => changeCSVDriverInfo(i, 0, e.target.value)} value={driver[0]}/></Col> {/* CODICE GARA */}
                                <Col md="3"><input onChange={(e) => changeCSVDriverInfo(i, 1, e.target.value)} value={driver[1]}/></Col> {/* NOME */}
                                <Col md="3"><input onChange={(e) => changeCSVDriverInfo(i, 2, e.target.value)} value={driver[2]}/></Col> {/* COGNOME */}
                                <Col md="2"><input onChange={(e) => changeCSVDriverInfo(i, 3, e.target.value)} value={driver[3]}/></Col> {/* CODICE PILOTA */}
                                <Col md="2"><button onClick={(e) => {
                                    newDriverFromCSV.splice(i, 1)
                                    setNewDriverFromCSV([...newDriverFromCSV])
                                }} className="btn btn-primary">Elimina</button></Col> {/* CODICE PILOTA */}
                                <br/>
                                <br/>
                            </Row>
                        )
                    }
                </div>
          </Drawer>
        </Col>
    )
}

function DriverItem(props) {
// ------------ STATES -------------

    let info = props.driverInfo
    const handler = props.handler

    useEffect(() => {
    }, [props.driverInfo])

// ------------ HANDLER FUNCTION ----

    return (
        <div className={"driver-item " + (handler.selectedDriver['id'] === info.id ? "item-selected" : "")}
        id={info.id}
        onClick={() => handler.setSelectedDriver(info)}>
            <div>
                <h5 className="m-0">{info.surname} {info.name}</h5>
                <p className="m-0">{info.driver_code}</p>
            </div>
            <div>
                <ExitIcon className="touchable" width="15px" height="15px" onClick={() => handler.deleteDriver(props.driverInfo)}/>
            </div>
        </div>
    )
}

function DriverForm(props) {
    const ADD_TRIAL_MODE = "add"
    const EDIT_TRIAL_MODE = "edit"
    const DELETE_TRIAL_MODE = "delete"
// ------------ STATES -------------
    const setMessage = props.setMessage
    const isPointRace = props.isPointRace
    const trialsList = props.trialsList
    const setTrialsList = props.setTrialsList
    const [info, setInfo] = useState(new Driver({name: "undefined"}, isPointRace, trialsList))
    const [saved, setSaved] = useState(false)
    const [timeFormatErrors, setTimeFormatErrors] = useState({})
    const setDriverList = props.setDriverList
    const driverList = props.driverList
    const setIsPointRace = props.setIsPointRace
    const setSaving = props.setSaving

// ------------ USEFFECT -----------

    useEffect(() => {
        setInfo(props.selectedDriver)
    }, [])


    useEffect(() => {
        let errors = {}
        for(let element of Object.keys(trialsList)) errors[element] = false
        setInfo(props.selectedDriver)
        setTimeFormatErrors(errors)
    }, [trialsList])

    useEffect(() => {
        if(!checkCorrectTimeFormat()) {
            if(props.selectedDriver.name != "undefined" && !saved) {
                saveDriver(info)
            }
            setInfo(props.selectedDriver)
            let errors = {}
            for(let element of Object.keys(trialsList)) errors[element] = false
            setTimeFormatErrors(errors)
            setSaved(true)
        } else {
            if(info.id != props.selectedDriver.id) props.setSelectedDriver({...info})
        }
    }, [props.selectedDriver])

    useEffect(() => {                                                               // *** TOGLIE IL FORM QUANDO ELIMINO UN PILOTA ***
        if(!Object.keys(driverList).includes(props.selectedDriver.id?.toString()))
            props.setSelectedDriver(new Driver({name: "undefined"}, isPointRace, trialsList))
    }, [driverList])

    useEffect(() => {
        setInfo(new Driver({name: "undefined"}, isPointRace, trialsList))
    }, [props.isPointRace])

// ----------- FUNCTION HANDLER -----
    
    function checkCorrectTimeFormat() {                                     // *** It returns false(no errors) if isPointRace is equal to true ***
        if(!isPointRace) {
            let errors = {}
            for(let element of Object.keys(trialsList)) {
                if(info[element]) {
                    errors[element] = isNaN(new Date('0 00:' + info[element]).getMilliseconds())
                }
            }
            setTimeFormatErrors(errors)
            return Object.values(errors).some(Boolean)       // checks if there is at least one error
        } else return false;
    }

    function onChangeHandler(e) {
        let name = e.target.name
        let value = e.target.value
        let newDriverList = driverList
        newDriverList[info['id']][name] = value
        setDriverList({...newDriverList})
        setSaved(false)
        setSaving(null)
    }

    function buildTimeFormat(t) {
        let time = new Date("0 00:" + t)
        let minutes = ('0' + time.getMinutes()).slice(-2)
        let seconds = ('0' + time.getSeconds()).slice(-2)
        let milliseconds = ('00' + time.getMilliseconds()).slice(-3)
        return minutes + ":" + seconds + ":" + milliseconds
    }

    function saveDriver(driverInfo = "") {
        if(!checkCorrectTimeFormat()) {
            let id = driverInfo.id
            driverInfo = isNaN(info.id) ? info : {...info, id: ""}
            if(!isPointRace) {
                for(let element of Object.keys(trialsList)) 
                    driverInfo[element] = buildTimeFormat(driverInfo[element])
            }
            setSaving(true)
            DB.setDriver(driverInfo, (result) => {
                setSaving(false)
                driverInfo.id = result.id
                let newDriverList = driverList
                delete newDriverList[id]
                setDriverList({...newDriverList, [result.id]: driverInfo})
            })
        }
    }

    return (
        <Col md="7" className="gray-bkg my-column scrollable" id="right">
            <h5>Informazioni pilota</h5>
            <br />
            {info['name'] != "undefined" ? 
                <form>
                    <h6>Nome</h6>
                    <input type="text" className="max-width" name="name" value={info['name'] != undefined ? info['name'] : "" } onChange={(e) => onChangeHandler(e)}/>
                    <br /><br /><br />
                    <h6>Cognome</h6>
                    <input type="text" className="max-width" name="surname" value={info['surname'] != undefined ? info['surname'] : "" } onChange={(e) => onChangeHandler(e)}/>
                    <br /><br /><br />
                    <Row>
                        <Col>
                            <h6>Codice identificativo</h6>
                            <input type="text" className="max-width" name="driver_code" value={info['driver_code'] != undefined ? info['driver_code'] : "" } onChange={(e) => onChangeHandler(e)}/>
                        </Col>
                        <Col>
                            <h6>Numero di gara</h6>
                            <input type="text" className="max-width" name="race_number" value={info['race_number'] != undefined ? info['race_number'] : "" } onChange={(e) => onChangeHandler(e)}/>
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <h6>Risultati prove</h6>

                    <div className='three_cols_layout'>
                        {
                            Object.keys(trialsList).map(ID => 
                                <div>
                                    <h6 className="text-center">{trialsList[ID]}</h6>
                                    <input
                                        type="text"
                                        className={"max-width text-center " + (timeFormatErrors[ID] && "error")}
                                        name={ID}
                                        value={info[ID]}
                                        onChange={(e) => onChangeHandler(e)}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <br/>
                    <div className='text-center'>
                        <Button onClick={() => saveDriver(info)}>Salva</Button>
                    </div>
                </form> : 
                "Seleziona un pilota..."
            }
        </Col>
    )
}

export default Dashboard
