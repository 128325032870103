import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import {Container} from "react-bootstrap"


import Login from "./pages/login"
import Dashboard from "./pages/dashboard"
import Error from "./pages/404"
import Test from "./pages/test"

import 'bootstrap/dist/css/bootstrap.min.css';
import "./style.css"


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/">
            <Dashboard />
        </Route>
        <Container> 
          <Route path="/login">
            <Login />
          </Route>
          {/*<Route path="/test">
            <Test />
          </Route>*/}
        </Container>
      </Switch>
    </Router>
    
  </React.StrictMode>,
  document.getElementById('root')
);
